document.addEventListener('DOMContentLoaded', () => {
  const filterForm = document.querySelector('#filter-anaf-http-requests');

  if (!filterForm) return;

  const companyNameInput = document.querySelector('input[list="working-companies-list"]');
  const companyIdInput = document.querySelector('input[name="working_company_id"]');
  const companiesList = document.querySelector('#working-companies-list');

  companyNameInput.addEventListener('input', function() {
    const inputValue = this.value;
    const options = companiesList.options;

    let matchingOption = null;
    for (const option of options) {
      if (option.value === inputValue) {
        matchingOption = option;
        break;
      }
    }

    if (matchingOption) {
      companyIdInput.value = matchingOption.getAttribute('data-id');
      filterForm.submit();
    } else {
      if (inputValue.trim() === '') {
        companyIdInput.value = ''; // Clear the hidden input if the company name input is cleared
      }
    }
  });

  const formElements = filterForm.querySelectorAll('select, input[type="text"], input[type="date"]');

  formElements.forEach((element) => {
    element.addEventListener('change', () => {
      filterForm.submit();
    });
  });
});
