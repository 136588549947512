document.addEventListener('DOMContentLoaded', () => {
  const times = document.querySelectorAll('.time');
  times.forEach((time) => {
    const date = new Date(time.innerHTML);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const dateString = date.toLocaleDateString('ro-RO', options);

    const timeString = date.toLocaleTimeString('ro-RO', { hour12: false, hour: '2-digit', minute: '2-digit' });

    time.innerHTML = `${dateString} ${timeString}`;
  });

  const dates = document.querySelectorAll('.date');
  dates.forEach((dateElement) => {
    const date = new Date(dateElement.innerHTML);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const dateString = date.toLocaleDateString('ro-RO', options);
    dateElement.innerHTML = dateString;
  });
});
