document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('.print-invoice-button')
  if(buttons.length === 0) return;
  const pdfIframe = document.getElementById('fast-print-pdf');
  let currentButton;

  pdfIframe.onload = () => {
    currentButton.disabled = false;
    pdfIframe.contentWindow.print();
  }

  buttons.forEach((button) => {
    button.addEventListener('click', async (e) => {
      currentButton = button;
      e.preventDefault();
      const invoiceId = button.dataset.invoiceId;
      const officialPdfUrl = `/e_invoices/${invoiceId}/pdf_official.pdf`;
      button.disabled = true;


      if(!invoiceId || !officialPdfUrl || officialPdfUrl.length < 1){
        return button.disabled = false;
      }

      pdfIframe.src = officialPdfUrl;

      const parentBtn = button.closest('div')
      if(parentBtn.querySelector('.printed-check')){
        return;
      }

      const check = `<div class="t-flex t-items-center t-justify-center printed-check t-ml-2">
        <ion-icon name="checkbox-outline" class="t-text-base"></ion-icon>
      </div>`;

      parentBtn.insertAdjacentHTML('beforeend', check);
    })
  })
})