document.addEventListener('DOMContentLoaded', function() {
  const buttons = document.querySelector('.button-date-range-fields');
  if(!buttons) return;

  const backBtn = buttons.querySelector('.back-date-range')
  const nextBtn = buttons.querySelector('.next-date-range')
  const startDateInput = document.getElementById(buttons.dataset.startDateId)
  const endDateInput = document.getElementById(buttons.dataset.endDateId)
  const form = buttons.closest('form')


  backBtn.addEventListener('click', function(e) {
    e.preventDefault();
    const startDate = new Date(startDateInput.value);
    const newStartDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
    const newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0); // Last day of the same month

    startDateInput.value = formatDate(newStartDate);
    endDateInput.value = formatDate(newEndDate);

    form.submit();
  });

  nextBtn.addEventListener('click', function(e) {
    e.preventDefault();
    const startDate = new Date(startDateInput.value);
    const newStartDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
    const newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0); // Last day of the next month

    startDateInput.value = formatDate(newStartDate);
    endDateInput.value = formatDate(newEndDate);
    form.submit();
  });

  function formatDate(date) {
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Month needs to be +1 because months are 0 indexed
    let day = ('0' + date.getDate()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${month}-${day}`; // Format required for date input values
  }
})