document.addEventListener('DOMContentLoaded', () => {
  const selectInvoicesForm = document.getElementById('select-company-e-invoices');

  if (!selectInvoicesForm) return;

  const selectElements = selectInvoicesForm.querySelectorAll('select');

  selectElements.forEach((select) => {
    select.addEventListener('change', function() {
      selectInvoicesForm.submit();
    });
  });

  const subCompanyNameInput = document.getElementById('sub_company_name');

  subCompanyNameInput.addEventListener('input', function() {
    const inputValue = this.value;
    const dataList = document.getElementById('sub-companies-list');
    const options = dataList.options;

    let matchingOption = null;
    for (const option of options) {
      if (option.value === inputValue) {
        matchingOption = option;
        break;
      }
    }

    if (matchingOption) {
      const subCompanyIdInput = document.querySelector('input[name="sub_company_id"]');
      subCompanyIdInput.value = matchingOption.getAttribute('data-id');

      selectInvoicesForm.submit();
    }
  });

  document.querySelectorAll('.clear-input').forEach(clearButton => {
    clearButton.addEventListener('click', function(e) {
      e.preventDefault()
      const inputGroup = this.closest('.input-group');
      const inputField = inputGroup.querySelector('input.form-control');

      // Clear the input field
      if (inputField) {
        if(inputField.value.length === 0) return;

        inputField.value = '';

        const relatedHiddenInput = document.querySelector(`input[type="hidden"][name="${inputField.name.replace('_name', '_id')}"]`);
        if (relatedHiddenInput) {
          relatedHiddenInput.value = '';
        }

        inputGroup.closest('form').submit();
      }
    });
  });
});
