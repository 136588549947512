document.addEventListener('DOMContentLoaded', () => {
  const table = document.querySelector('#e-invoices-table');
  if (!table) return;

  const subCompanyNamesTd = table.querySelectorAll('td.sub-company-name');
  const subCompaniesList = document.querySelector('datalist#sub-companies-list');
  const subCompanyInput = document.querySelector('#sub_company_name');
  const subCompanyIdInput = document.querySelector('#sub_company_id');
  const form = document.querySelector('form#select-company-e-invoices');

  subCompanyNamesTd.forEach(subCompanyNameTd => {
    subCompanyNameTd.addEventListener('click', () => {
      const subCompanyName = subCompanyNameTd.textContent.trim();
      const options = subCompaniesList.querySelectorAll('option');
      const matchingOption = Array.from(options).find(option => option.value === subCompanyName);
      if(matchingOption.value === subCompanyInput.value) return;

      // If a matching option is found, set the input value to the sub-company name
      if(matchingOption) {
        const id = matchingOption.getAttribute('data-id');
        subCompanyInput.value = subCompanyName;
        subCompanyIdInput.value = id;
        form.submit();
      } else {
        console.log("No matching sub-company name found in the datalist.");
      }
    });
  });
});
