document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.preview-invoice-button');

  if (elements.length === 0) return;

  const dialog = document.getElementById('previewInvoice');
  const pdfFrameContainer = document.getElementById('pdfFrame');
  let pdfFrame;
  const pdfFrameAnafContainer = document.getElementById('pdfFrameAnaf');
  let pdfFrameAnaf;

  const body = document.body; // Get the body element
  const tabPdfAnafButton = document.getElementById('pdf-anaf-tab');
  const tabPdfStandardButton = document.getElementById('pdf-standard-tab');

  function createEmbed(url){
    const embed = document.createElement('embed');
    embed.src = url;
    embed.type = 'application/pdf';
    embed.classList.add('t-w-full', 't-h-full');
    embed.setAttribute('frameborder', '0');

    return embed;
  }

  const closeDialog = () => {
    tabPdfStandardButton.click();
    dialog.close();
    if(pdfFrame){
      pdfFrameContainer.removeChild(pdfFrame);
      pdfFrame = null;
    }

    if(pdfFrameAnaf){
      pdfFrameAnafContainer.removeChild(pdfFrameAnaf);
      pdfFrameAnaf = null;
    }
    body.style.overflow = '';
  }

  dialog.querySelector('.close-btn').addEventListener('click', (e) => {
    e.preventDefault()
    closeDialog();
  });

  tabPdfAnafButton.addEventListener('click', (e) => {
    e.preventDefault();
    const { pdfUrl } = e.target.dataset;
    if(pdfFrameAnaf) return;
    pdfFrameAnaf = createEmbed(pdfUrl);
    pdfFrameAnafContainer.appendChild(pdfFrameAnaf);
  });

  elements.forEach((btn) => {
    btn.addEventListener('click', async (e) => {
      e.preventDefault();

      const invoiceId = btn.dataset.invoiceId;
      const debugBtn = dialog.querySelector('.debug-btn');
      if(debugBtn){
        debugBtn.href = `/e_invoices/${invoiceId}/debug`;
      }
      const pdfUrl = btn.href;
      const officialPdfUrl = `/e_invoices/${invoiceId}/pdf_official.pdf`;

      pdfFrame = createEmbed(pdfUrl);
      pdfFrameContainer.appendChild(pdfFrame);

      tabPdfAnafButton.dataset.pdfUrl = officialPdfUrl;

      dialog.showModal();
      dialog.scrollTop = 0;
      body.style.overflow = 'hidden'; // Prevent scrolling when dialog is open

      dialog.focus();
    });
  });

  dialog.addEventListener('click', (event) => {
    if (event.target === dialog) {
      closeDialog();
    }
  });

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeDialog();
    }
  });
});
