document.addEventListener('DOMContentLoaded', () => {
  const dateRangeFields = document.querySelector('.date-range-fields')
  if(!dateRangeFields) return

  const inputs = dateRangeFields.querySelectorAll('input[type="date"]')
  const form = dateRangeFields.closest('form')

  inputs.forEach(input => {
    let isTyping = false
    const initialValue = input.value

    input.addEventListener('keydown', (e) => {
      isTyping = true
      if(e.key === 'Enter') {
        return form.submit()
      }
    })

    input.addEventListener('blur', (e) => {
      isTyping = false
      if(input.value !== initialValue){
        form.submit()
      }
    })

    input.addEventListener('change', (e) => {
      if(!isTyping){
        form.submit()
      }
    })
  })
})